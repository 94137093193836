.st_stacks_title {
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-md);
}

.st_headerbar_custom_module {
  margin: 0 0 50px 0;
  font-size: 25px;
  color: rgb(119, 119, 119);
  font-weight: bold;
}

.st_headerbar_custom_module_back {
  margin: 50px 0 0 0;
}
.okta-setting .st_tablecell--header {width: 200px;}

.st-token-link {
  cursor: pointer;
  border: 0;
}
/* dl {
    display: grid;
    grid-template-columns: 200px  auto;

}
dt {
  color: var(--st-color-ink);
  margin-right: var(--st-spacer-sm);
  margin-bottom: 2px;
  font-family: var(--st-font-stack);
  font-weight: var(--st-font-weight-reg);
  font-size: var(--st-font-size-md);
  line-height: var(--st-font-line-height-md);
}
dd {
  color: var(--st-color-asphalt);
  margin: 0;
  padding: 0;
  font-family: var(--st-font-stack);
  font-weight: var(--st-font-weight-reg);
  font-size: var(--st-font-size-md);
  line-height: var(--st-font-line-height-md);
}

dd .st_token {
  display: table;
} */

.productListWrapper {
  border-top: 1px solid var(--st-border-color);
  border-right: 1px solid var(--st-border-color);
  border-bottom: 1px solid var(--st-border-color);
  border-left: 0;
  display: inline-flex;
  margin:  var(--st-spacer-sm) 0;
}

.productListWrapper .productListItem {
   padding: var(--st-spacer-xs);
}
.productListWrapper .productListItem + .productListItem {
  margin-left: 0;
}