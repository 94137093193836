.st_assigned_group {
  display: flex;
  justify-content: space-between;
}

.st_assignment_button_container {
  display: flex;
  justify-content: flex-end;
}

.st_btn.st_destructive_button.st_m-top-lg.st_btn--secondary {
  border: 1px solid var(--st-color-lava-50);
  color: var(--st-color-lava-50);
}

.st_btn.st_destructive_button.st_m-top-lg.st_btn--secondary:hover {
  background: var(--st-color-lava-05);
}
