table.table table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 15px 0px;
}

table.table td,
table.table th {
  -webkit-box-align: center;
  align-items: center;
  color: rgb(68, 68, 68);
  -webkit-box-pack: center;
  justify-content: center;
  outline: none;
}

table.table tr:nth-child(even) {
  background-color: var(--st-color-blurple-10);
}

.completed,
.rolled_back {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(68, 68, 68);
  min-width: 0px;
  min-height: 0px;
  flex-direction: row;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  outline: none;
  background: rgb(0, 199, 129);
  flex: 1 0 auto;
  margin: 5px 0;
}

.build-sha {
  box-sizing: border-box;
  font-size: inherit;
  line-height: inherit;
  color: rgb(162, 66, 61);
  cursor: pointer;
  text-decoration: none;
  outline: none;
}

.in_progress,
.rolling_back {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(68, 68, 68);
  min-width: 0px;
  min-height: 0px;
  flex-direction: row;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  outline: none;
  background: rgb(255, 195, 11);
  flex: 1 0 auto;
  margin: 5px;
}
