.st_app_title_box {
  display: flex;
  justify-content: space-between;
}

.st_trunacted_text.st_trunacted_text {
  max-width: 85%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.st_app_box_content > div {
  box-sizing: border-box;
  font-size: inherit;
  font-weight: 500;
  text-decoration: none;
  outline: none;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  word-break: break-all;
}

.st_app_box_content .st_app_box_url {
  color: rgb(119, 119, 119);
}

.st_app_box_content .st_app_box_date {
  color: rgb(218, 218, 218);
}

.st_app_box_content .st_app_box_builds {
  color: rgb(165, 165, 165);
}
