.st_stacks_title {
  font-family: var(--st-font-stack);
  font-size: var(--st-font-size-md);
}

.st_headerbar_custom {
  margin: 50px 0;
  font-size: 25px;
  color: rgb(119, 119, 119);
  font-weight: bold;
}
