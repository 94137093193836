.tb-content {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
}

.tb-content.tb-celled {
  border: thin solid var(--st-border-color);
  /* padding: var(--st-spacer-xs); */
}

table.table {
  width: 100%;
}

table.table th,
table.table td {
  text-align: left;
  max-width: 20rem;
  overflow: hidden;
  word-break: break-all;
}
