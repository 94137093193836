.request-empty-content {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 800px;
}

.request-empty-content,
.request-empty-illustration {
  width: 100%;
  max-width: 800px;
}

.request-access-container {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
}

.access-group-select {
  display: flex;
}

.access-group-overlay {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.access-group-overlay > div {
  width: 100%;
}

.access-info-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
}

.access-illustration {
  width: 100%;
  max-width: 400px;
}

.access-details-line {
  margin: 8px;
}

.access-no-group {
  cursor: default;
}
