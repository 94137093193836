.st_headerbar_custom {
  margin: 40px 15px;
  width: auto;
  z-index: 0;
}

.st_column_application {
  float: left;
  width: 33.33%;
}

/* Clear floats after the columns */
.st_row_application:after {
  content: '';
  display: table;
  clear: both;
}
